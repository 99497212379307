import React, { useEffect, useReducer, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Tab,
    IconButton
} from "@material-ui/core";
import api from "../../services/api";
import AgendaRoutineEditModal from "../AgendaRoutineEditModal";
import ConfirmationModal from "../ConfirmationModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import openSocket from "../../services/socket-io";


const reducer = (state, action) => {
    if (action.type === "LOAD_ROUTINES") {
        const routines = action.payload;
        const newRoutines = [];

        routines.forEach((routine) => {
            const routineIndex = state.findIndex((r) => r.id === routine.id);
            if (routineIndex !== -1) {
                state[routineIndex] = routine;
            } else {
                newRoutines.push(routine);
            }
        });

        return [...state, ...newRoutines];
    }

    if (action.type === "UPDATE_ROUTINE") {
        const routine = action.payload;
        const routineIndex = state.findIndex((r) => r.id === routine.id);

        if (routineIndex !== -1) {
            state[routineIndex] = routine;
            return [...state];
        } else {
            return [...state, routine];
        }
    }

    if (action.type === "DELETE_ROUTINE") {
        const routineId = action.payload;
        return state.filter((routine) => routine.id !== routineId);
    }
};

const AgendaRoutineList = () => {
    const [routines, dispatch] = useReducer(reducer, []);
    const [routineModalOpen, setRoutineModalOpen] = useState(false);
    const [selectedRoutine, setSelectedRoutine] = useState(null);

    const [deletingRoutine, setDeletingRoutine] = useState(null);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);


    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/agendas/routine");
                dispatch({ type: "LOAD_ROUTINES", payload: data });
                
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    useEffect(() => {
        const socket = openSocket();

        socket.on("agenda", (data) => {
            if (data.action === "updateRoutine" || data.action === "createRoutine") {
                dispatch({ type: "UPDATE_ROUTINE", payload: data.agendaRoutine });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const handleCloseRoutineModal = () => {
        setRoutineModalOpen(false);
    };

    const handleEditRoutine = (routine) => {
        setSelectedRoutine(routine);
        setRoutineModalOpen(true);
    };

    const handleDeleteRoutine = async (routineId) => {
        try {
            await api.delete(`/agendas/routine/${routineId}`);
            dispatch({ type: "DELETE_ROUTINE", payload: routineId });

            toast.success("Rotina deletada com sucesso!");
            setDeletingRoutine(null);
            setConfirmationModalOpen(false);

        } catch (error) {
            console.error("Erro ao deletar rotina:", error);
            toastError(error);
        }
    };

    return (
        <div>
            <ConfirmationModal
                open={confirmationModalOpen}
                onClose={() => setConfirmationModalOpen(false)}
                onConfirm={() => handleDeleteRoutine(deletingRoutine.id)}
                title={"Deletar Rotina"}
            >
                Deseja realmente deletar essa rotina? Essa ação será definitiva.
            </ConfirmationModal>
            <AgendaRoutineEditModal
                open={routineModalOpen}
                onClose={handleCloseRoutineModal}
                routineId={selectedRoutine && selectedRoutine.id}
            />
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Título</TableCell>
                        <TableCell align="center">Mensagem</TableCell>
                        <TableCell align="center">Rotina de envio</TableCell>
                        <TableCell align="center">Confirmação</TableCell>
                        <TableCell align="center">Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {routines.map((routine) => (
                        <TableRow key={routine.id}>
                            <TableCell align="center">{routine.title}</TableCell>
                            <TableCell align="center">{routine.message}</TableCell>
                            <TableCell align="center">{`${routine.timeBefore} ${routine.timeUnit} antes`}</TableCell>
                            <TableCell align="center">{routine.confirmation ? "Sim" : "Não"}</TableCell>
                            <TableCell align="center">
                                <IconButton
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleEditRoutine(routine)}
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        setDeletingRoutine(routine)
                                        setConfirmationModalOpen(true)
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </div>
    );
};

export default AgendaRoutineList;