import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { Avatar, Dialog, DialogContent, Grid, IconButton, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { set } from 'date-fns';
import EventsModalEdit from '../EventsModalEdit';
import { toast } from 'react-toastify';
import { date } from 'yup';
import ConfirmationModal from '../ConfirmationModal';

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: "wrap",
        padding: theme.spacing(2),
    },
    title: {
        fontSize: "1.0rem",
    },
    description: {
        fontSize: "0.85rem",
    },
    customDialog: {
        width: "400px",  // Define a largura do modal
        maxWidth: "400px",  // Certifica-se de que o modal não vai além de 400px
        borderTop: (props) => `8px solid ${props.color}`,  // Define a cor da borda superior do modal

    },
    status: {
        fontSize: "1.0rem",
        color: (props) => props.color,
    },
}));

const Event = ({ eventId, open, onClose }) => {
    const [event, setEvent] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [date, setDate] = useState('');
    const [deletingEvent, setDeletingEvent] = useState(null);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await api.get(`/events/${eventId}`);
                //transforma a data no formato brasileiro
                const dateFormated = data.date.split('-').reverse().join('/');
                const date = data.allDay ? `${dateFormated} o dia todo` : `${dateFormated} às ${(data.start)}`;
                setDate(date);
                setEvent(data);
            } catch (error) {
                console.error('Erro ao buscar os dados do evento:', error);
                toastError(error);
            }
        };

        fetchData();
    }, [eventId]);


    //função para formatar a data
    const formatDate = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const startTime = startDate.toLocaleTimeString().slice(0, 5);
        const endTime = endDate.toLocaleTimeString().slice(0, 5);

        return `${startDate.toLocaleDateString()} às ${startTime} - ${endDate.toLocaleDateString()} às ${endTime}`;
    };

    const handleModalClose = () => {
        setModalOpen(false);
        onClose();
    };

    const handleDeleteEvent = async (eventId) => {
        try {
            await api.delete(`/events/${eventId}`);
            toast.success("Evento deletado com sucesso!");
            setDeletingEvent(null);
            onClose();
        } catch (error) {
            console.error("Erro ao deletar evento:", error);
            toastError(error);
        }
    };

    const color = event?.color || "#3f51b5";

    const classes = useStyles({ color });

    return (
        <div className={classes.root}>
            <ConfirmationModal
                open={confirmationModalOpen}
                onClose={() => setConfirmationModalOpen(false)}
                onConfirm={() => handleDeleteEvent(deletingEvent.id)}
                title="Deletar Evento"
            >
                Deseja realmente deletar esse evento? Essa ação será definitiva.
            </ConfirmationModal>

            {event && (
                <EventsModalEdit
                    event={event}
                    open={modalOpen}
                    onClose={handleModalClose}
                    agendaName={event?.agenda?.name}
                />
            )}
            <Dialog
                open={open}
                onClose={onClose}
                fullWidth
                maxWidth="xs" // 'xs' está perto de 400px, mas será sobreposto pelo customDialog
                classes={{ paper: classes.customDialog }} // Customiza o estilo do modal
                style={{ borderTopColor: color }} // Define a cor da borda superior do modal
            >
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item container xs={12} direction='row'>
                            <Grid item>
                                <Typography variant="h6">{event?.title}</Typography>
                            </Grid>
                            <Grid item style={{ marginLeft: "auto" }}>
                                <Typography variant="overline text" className={classes.status}>{event?.status}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" className={classes.title}>Contato</Typography>
                            <Typography variant="body1" className={classes.description}>{`${event?.contact.name} - ${event?.contact.number}`}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" className={classes.title}>Horário</Typography>
                            <Typography variant="body1" className={classes.description}>{date}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" className={classes.title}>Descrição</Typography>
                            <Typography variant="body1" className={classes.description}>{event?.description ? (event.description) : ("sem descrição")}</Typography>
                        </Grid>
                        <Grid item style={{ marginLeft: "auto" }}>
                            <IconButton
                                color="primary"
                                onClick={() => setModalOpen(true)}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                color="secondary"
                                onClick={() => {
                                    setDeletingEvent(event);
                                    setConfirmationModalOpen(true); // Abre o modal de confirmação após um pequeno delay
                                }}
                            >
                                <DeleteOutlineIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div >
    );
};

export default Event;