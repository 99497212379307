import React, { useEffect, useState } from "react";
import { toString } from "lodash";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { set } from "date-fns";
import { ClickAwayListener, List, ListItem, TableBody, TableCell, Table, TableRow } from "@material-ui/core";


const IntervalsDateList = ({ date, agendaId, open }) => {
    const [intervalsData, setIntervalsData] = useState([]);
    const [newLoading, setNewLoading] = useState(false);

    useEffect(() => {
        if (!date) return;
        if (!agendaId) return;

        setNewLoading(true);

        console.log("date", date);
        console.log("agendaId", agendaId);

        const fetchIntervals = async () => {
            try {
                const { data } = await api.get("/events", { params: { agendaId } })
                const calendar = await api.get(`/agendas/${agendaId}`);

                const agenda = calendar.data;

                const events = data.filter(event => event.date === date);

                console.log(events);
                console.log(data);
                console.log(agenda);

                let intervals = [];

                if (events.length === 0) {
                    intervals = [{ intervalStart: agenda.startTime, intervalEnd: agenda.endTime }];
                } else {

                    const HHstart = toString(events[0].start).split(":")[0];
                    const MMstart = parseInt(toString(events[0].start).split(":")[1]) - 1;

                    intervals = [
                        {
                            intervalStart: `${agenda.startTime}`,
                            intervalEnd: MMstart < 10 ? `${HHstart}:0${MMstart}` : `${HHstart}:${MMstart}`
                        }
                    ]
                    if (events.length > 1) {
                        for (let i = 0; i < events.length - 1; i++) {
                            const HHstart = toString(events[i].end).split(":")[0];
                            const MMstart = parseInt(toString(events[i].end).split(":")[1]) + 1;
                            const HHend = toString(events[i + 1].start).split(":")[0];
                            const MMend = parseInt(toString(events[i + 1].start).split(":")[1]) - 1;

                            intervals.push({
                                intervalStart: MMstart < 10 ? `${HHstart}:0${MMstart}` : `${HHstart}:${MMstart}`,
                                intervalEnd: MMend < 10 ? `${HHend}:0${MMend}` : `${HHend}:${MMend}`
                            });
                        }
                    }

                    const HHend = toString(events[events.length - 1].end).split(":")[0];
                    const MMend = parseInt(toString(events[events.length - 1].end).split(":")[1]) + 1;


                    intervals.push({
                        intervalStart: MMend < 10 ? `${HHend}:0${MMend}` : `${HHend}:${MMend}`,
                        intervalEnd: `${agenda.endTime}`
                    });

                }

                setIntervalsData(intervals);
                console.log(intervals);
                setNewLoading(false);

            } catch (error) {
                console.log(error);
                setNewLoading(false);
                toastError(error);
            }
        };

        fetchIntervals();
    }, [date, agendaId, open]);


    return (
        <div>
            {newLoading && <p>Carregando...</p>}

            {!newLoading && (
                <Table>

                    {intervalsData.map((interval, index) => (
                        <TableRow key={index} align="center">
                            <TableCell align="center" colSpan={2}>
                                {`${interval.intervalStart} - ${interval.intervalEnd}`}
                            </TableCell>
                        </TableRow>
                    ))}

                </Table>
            )}
        </div>
    );

};

export default IntervalsDateList;
