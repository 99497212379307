import React, { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import moment from "moment";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/pt-br"; // Importa o locale de português brasileiro

import MainContainer from "../MainContainer";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import MainHeader from "../MainHeader";
import Title from "../Title";
import { Box, ClickAwayListener, IconButton, Tooltip, Typography } from "@material-ui/core";
import AgendaModal from "../AgendaModal";
import AgendaRoutineModal from "../AgendaRoutineModal";
import AgendasList from "../AgendasList";
import toastError from "../../errors/toastError";
import { set } from "date-fns";
import EventsModalEdit from "../EventsModalEdit";
import EventsModal from "../EventsModal";
import Event from "../Event";
import { toast } from "react-toastify";
import openSocket from "../../services/socket-io";
import MainHeaderButtonsWrapper from "../MainHeaderButtonsWrapper";
import { ReactComponent as GearFill } from "bootstrap-icons/icons/gear-fill.svg";
import { ReactComponent as PlusCircleFill } from "bootstrap-icons/icons/plus-circle-fill.svg";
import { ReactComponent as Home } from "bootstrap-icons/icons/house-fill.svg";
import { ReactComponent as Caption } from "bootstrap-icons/icons/chat-square-text.svg";
import AgendaModalEdit from "../AgendaModalEdit";


// Configura o moment para usar o locale pt-br
moment.locale("pt-br");

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(BigCalendar);

// Textos em português para o calendário
const messages = {
    next: "Próximo",
    previous: "Anterior",
    today: "Hoje",
    month: "Mês",
    week: "Semana",
    day: "Dia",
    agenda: "Agenda",
    date: "Data",
    time: "Hora",
    event: "Evento",
    noEventsInRange: "Nenhum evento neste intervalo",
    showMore: (total) => `+ Ver mais (${total})`
};

const reducer = (state, action) => {
    if (action.type === "LOAD_EVENTS") {
        return action.payload;
    }

    if (action.type === "UPDATE_EVENT") {
        const event = action.payload;
        const eventIndex = state.findIndex((e) => e.id === event.id); // Encontra o índice do evento no array

        if (eventIndex !== -1) { // Se o evento existe, atualiza ele
            state[eventIndex] = event;
            return [...state];
        } else {
            return [event, ...state];
        }
    }

    if (action.type === "DELETE_EVENT") {
        const eventId = action.payload;

        const eventIndex = state.findIndex((e) => e.id === eventId);

        if (eventIndex !== -1) {
            state.splice(eventIndex, 1);
        }
        return [...state];
    }
};

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    textField: {
        marginRight: theme.spacing(1),
        width: "100%",
    },
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "auto",
    },
    buttonContainer: {
        margin: 2,
        padding: 8,
    },
    icon: {
        width: "35px",
        height: "35px"
    },
    dropdown: {
        position: 'absolute',
        top: 0,
        //right: -60,
        left: -188,
        zIndex: 5,
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: "#ededed", //#bfbaba
        maxWidth: 180,
        boxShadow: theme.shadows[5],
        width: 180,
    },
    box: {
        display: "flex",
        alignItems: "center",
        marginTop: "10px",
    },
    boxColor: {
        width: "12px",
        height: "12px",
        marginRight: "5px",
        border: "1px solid black",
    },
    calendar: {
        "@global": {
            ".rbc-calendar": {
                fontFamily: "'Nunito', sans-serif !important", // Estilo global
            },
            ".rbc-header": {
                fontWeight: "600 !important",
            },
            ".rbc-event": {
                fontWeight: "400 !important",
            },
            ".rbc-day-slot": {
                fontFamily: "'Nunito', sans-serif !important",
            },
        },
    }
}));

const Calendar = () => {
    const classes = useStyles();

    const { agendaId } = useParams();
    const [agenda, setAgenda] = useState(null);
    const [loading, setLoading] = useState(true);
    const [events, dispatch] = useReducer(reducer, []);
    const [eventSelected, setEventSelected] = useState(null);
    const [slotSelected, setSlotSelected] = useState(null);

    const [openEventModal, setOpenEventModal] = useState(false);
    const [openListener, setOpenListener] = useState(false);
    const [openAgendaModal, setOpenAgendaModal] = useState(false);
    const [agendaSelected, setAgendaSelected] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Usar Promise.all para garantir que ambas as requisições sejam feitas em paralelo
                const [agendaResponse] = await Promise.all([
                    api.get(`/agendas/${agendaId}`)
                ]);

                const eventsResponse = await api.get("/events", { params: { agendaId } })

                console.log(agendaResponse.data);
                console.log(eventsResponse.data);

                const formattedEvents = eventsResponse.data.map(event => ({
                    ...event,
                    start: moment((new Date(event.date + " " + event.start))).toDate(),
                    end: moment((new Date(event.date + " " + event.end))).toDate(),
                }));

                dispatch({ type: "LOAD_EVENTS", payload: formattedEvents });

                // Setar agenda e eventos com os dados das requisições
                setAgenda(agendaResponse.data);
                console.log("formatado ", formattedEvents);
            } catch (error) {
                console.error('Erro ao buscar os dados da agenda ou eventos:', error);
                toastError(error);
            } finally {
                // Garantir que o loading seja false após todas as requisições
                setLoading(false);
            }
        };

        fetchData();
    }, [agendaId]);

    useEffect(() => {
        const socket = openSocket();

        socket.on("event", (data) => {
            if (data.action === "update" || data.action === "create") {
                const formattedEvent = {
                    ...data.event,
                    start: moment(new Date(data.event.date + " " + data.event.start)).toDate(),
                    end: moment(new Date(data.event.date + " " + data.event.end)).toDate(),
                };
                dispatch({ type: "UPDATE_EVENT", payload: formattedEvent });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_EVENT", payload: +data.eventId });
            }
        });

        socket.on("agenda", (data) => {
            if (data.action === "update") {
                setAgenda(data.agenda);
            }
        });

        return () => {
            socket.disconnect();
        };

    }, []);

    /*useEffect(() => {

        const newEvents = events.map(event => ({
            ...event,
            start: moment(event.start).toDate(),
            end: moment(event.end).toDate(),
        }));

        dispatch({ type: "LOAD_EVENTS", payload: newEvents });

    }, [events]);*/


    const handleMoveEvent = async (data) => {
        const { event, start, end } = data;

        try {
            await api.put(`/events/${event.id}`, {
                start,
                end,
            });

            toast.success("Evento atualizado com sucesso!");

        } catch (error) {
            console.error("Erro ao atualizar evento:", error);
            toastError(error);

        }
    };

    const handleEventClick = (event) => {
        setEventSelected(event);
    };

    const handleEventClose = () => {
        setEventSelected(null);
    };

    const handleSelectSlot = (slotInfo) => {
        setSlotSelected(slotInfo);
        setOpenEventModal(true);
    }

    const handleClick = () => {
        setOpenListener((prev) => !prev);
    }

    const handleClickListener = () => {
        setOpenListener(false);
    }

    const handleEditAgendaModal = (agendaId) => {
        setAgendaSelected(agendaId);
        setOpenAgendaModal(true);
    }

    const handleCloseAgendaModal = () => {
        setAgendaSelected(null);
    }

    return (
        <MainContainer>
            {!loading && (
                <>
                    <MainHeader>
                        <Title>{`Calendário de ${agenda.name}`}</Title>
                        <MainHeaderButtonsWrapper>
                            <ClickAwayListener onClickAway={handleClickListener}>
                                <div style={{ position: "relative" }}>
                                    <Tooltip title="Legendas" placement="top">
                                        <IconButton
                                            onClick={handleClick}
                                        >
                                            <Caption
                                                className={classes.icon}
                                                style={{ color: "#7d4a31" }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    {openListener ? (
                                        <Paper className={classes.dropdown}>
                                            <Typography variant="h6" style={{ fontSize: "1rem" }}>Legenda</Typography>
                                            <Box className={classes.box}>
                                                <Box style={{ backgroundColor: "#2c4a94" }} className={classes.boxColor} />
                                                <Typography variant="caption" color="textSecondary">
                                                    Precisa de confirmação
                                                </Typography>
                                            </Box>
                                            <Box className={classes.box}>
                                                <Box style={{ backgroundColor: "#faef50" }} className={classes.boxColor} />
                                                <Typography variant="caption" color="textSecondary">
                                                    Aguardando Confirmação
                                                </Typography>
                                            </Box>
                                            <Box className={classes.box}>
                                                <Box style={{ backgroundColor: "#5aa65b" }} className={classes.boxColor} />
                                                <Typography variant="caption" color="textSecondary">
                                                    Confirmado
                                                </Typography>
                                            </Box>
                                            <Box className={classes.box}>
                                                <Box style={{ backgroundColor: "#e74c3c" }} className={classes.boxColor} />
                                                <Typography variant="caption" color="textSecondary">
                                                    Cancelado
                                                </Typography>
                                            </Box>
                                            <Box className={classes.box}>
                                                <Box style={{ backgroundColor: "#8f4ddb" }} className={classes.boxColor} />
                                                <Typography variant="caption" color="textSecondary">
                                                    Sem necessidade de confirmação
                                                </Typography>
                                            </Box>
                                        </Paper>
                                    ) : null}
                                </div>
                            </ClickAwayListener>
                            <Tooltip title="Configurar agenda" placement="top">
                                <IconButton
                                    onClick={() => handleEditAgendaModal(agenda.id)}
                                >
                                    <GearFill
                                        className={classes.icon}
                                        style={{ color: "#353b36" }}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Adicionar Evento" placement="top">
                                <IconButton
                                    onClick={() => setOpenEventModal(true)}
                                >
                                    <PlusCircleFill
                                        className={classes.icon}
                                        style={{ color: "#348c43" }}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Home" placement="top">
                                <IconButton
                                    color="primary"
                                    href="/agendas"
                                >
                                    <Home className={classes.icon} />
                                </IconButton>
                            </Tooltip>
                        </MainHeaderButtonsWrapper>
                    </MainHeader>

                    <Paper className={classes.mainPaper} variant="outlined">
                        <DnDCalendar
                            defaultDate={new Date()}
                            defaultView="month"
                            events={events}
                            localizer={localizer}
                            messages={messages} // Aplica as mensagens em português
                            onEventDrop={(e) => handleMoveEvent(e)}
                            onEventResize={(e) => handleMoveEvent(e)}
                            eventPropGetter={(event) => ({
                                style: {
                                    backgroundColor: event.color,
                                },
                            })}
                            resizable
                            selectable
                            onSelectEvent={handleEventClick}
                            onSelectSlot={(slotInfo) => {
                                handleSelectSlot(slotInfo);
                            }}
                            style={{ height: "100%" }}
                            className={classes.calendar}
                        />
                    </Paper>
                </>
            )
            }
            {
                eventSelected && (
                    <Event
                        eventId={eventSelected.id}
                        open={eventSelected !== null}
                        onClose={handleEventClose}
                    />
                )
            }
            {
                openEventModal && (
                    <EventsModal
                        slotInfo={slotSelected}
                        agendaId={agenda.id}
                        open={openEventModal}
                        onClose={() => {
                            setOpenEventModal(false)
                            setSlotSelected(null)
                        }}
                    />
                )
            }
            {agendaSelected && (
                <AgendaModalEdit
                    agendaId={agendaSelected}
                    open={agendaSelected !== null}
                    handleClose={handleCloseAgendaModal}
                />
            )}

            {/* <EventsModalEdit
                event={eventSelected}
                agendaName={agenda.name}
                open={eventSelected !== null}
                onClose={handleEventClose}
            />*/}
        </MainContainer >
    );
};

export default Calendar;