import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useState, useEffect, useContext } from 'react';
import useWhatsApps from '../../hooks/useWhatsApps';
import { AuthContext } from '../../context/Auth/AuthContext';
import { Can } from "../Can";

import api from '../../services/api';

import { i18n } from "../../translate/i18n";
import { toast } from 'react-toastify';
import { set } from 'date-fns';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
}));

const daysOfWeek = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];

const AgendaSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
});

const AgendaModalEdit = ({ open, handleClose, agendaId }) => {
    const classes = useStyles();

    const initialValues = {
        name: '',
        startTime: '',
        endTime: '',
        multipleEvents: false,
    };

    const { user: loggedInUser } = useContext(AuthContext);

    const { loading, whatsApps } = useWhatsApps();
    const [whatsappId, setWhatsappId] = useState(false);
    const [daysSelected, setDaysSelected] = useState([]);

    const [agenda, setAgenda] = useState(initialValues);
    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        setPageLoading(true);
        if (agendaId) {
            const fetchAgenda = async () => {
                try {
                    const response = await api.get(`/agendas/${agendaId}`);

                    const {
                        name,
                        monday,
                        tuesday,
                        wednesday,
                        thursday,
                        friday,
                        saturday,
                        sunday,
                        startTime,
                        endTime,
                        multipleEvents,
                        whatsappId } = response.data;

                    setWhatsappId(whatsappId);

                    const days = [
                        monday && 'Segunda',
                        tuesday && 'Terça',
                        wednesday && 'Quarta',
                        thursday && 'Quinta',
                        friday && 'Sexta',
                        saturday && 'Sábado',
                        sunday && 'Domingo',
                    ].filter(Boolean);

                    setDaysSelected(days);
                    console.log(days);

                    const agendaData = {
                        name,
                        startTime,
                        endTime,
                        multipleEvents
                    };

                    console.log(agendaData);

                    setAgenda(prevState => ({ ...prevState, ...agendaData }));

                } catch (error) {
                    toast.error('Erro ao buscar agenda!');
                }
            };

            fetchAgenda();
            setPageLoading(false);
        }
    }, [agendaId]);


    const handleSubmit = async (values) => {
        const agendaData = {
            ...values,
            selectedDays: daysSelected,
            whatsappId
        };

        console.log(agendaData);

        try {
            const response = await api.put(`/agendas/${agendaId}`, agendaData);

            toast.success('Agenda atualizada com sucesso!');
        } catch (error) {
            toast.error('Erro ao atualizar agenda!');
        }

        handleClose();
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Editar Agenda</DialogTitle>
            {!pageLoading && (
                <DialogContent dividers>
                    <Formik
                        initialValues={agenda}
                        enableReinitialize={true}
                        validationSchema={AgendaSchema}
                        onSubmit={(values, actions) => {
                            setTimeout(() => {
                                handleSubmit(values);
                                actions.setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({ values, isSubmitting, errors, touched, setFieldValue }) => (
                            <Form>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        name="name"
                                        as={TextField}
                                        label="Nome da agenda"
                                        fullWidth
                                        margin="dense"
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                    />

                                    <Can
                                        role={loggedInUser.profile}
                                        perform="user-modal:editQueues"
                                        yes={() => (!loading &&
                                            <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                                                <InputLabel>{i18n.t("userModal.form.whatsapp")}</InputLabel>
                                                <Field
                                                    as={Select}
                                                    value={whatsappId}
                                                    onChange={(e) => setWhatsappId(e.target.value)}
                                                    label={i18n.t("userModal.form.whatsapp")}
                                                >
                                                    <MenuItem value={''}>&nbsp;</MenuItem>
                                                    {whatsApps.map((whatsapp) => (
                                                        <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
                                                    ))}
                                                </Field>
                                            </FormControl>
                                        )}
                                    />
                                </div>

                                <Typography variant="h6" style={{ fontSize: "1rem", marginTop: 5 }}>
                                    Dias da Semana
                                </Typography>

                                <Grid container spacing={2}>
                                    {daysOfWeek.map((day) => (
                                        <Grid item xs={6} key={day}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={daysSelected.includes(day)}
                                                        onChange={() => {
                                                            const selected = daysSelected.includes(day) // se o dia já estiver selecionado, remove
                                                                ? daysSelected.filter((d) => d !== day) // remove
                                                                : [...daysSelected, day]; // adiciona
                                                            setDaysSelected(selected);
                                                        }}
                                                    />
                                                }
                                                label={day}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Field
                                            name="startTime"
                                            as={TextField}
                                            label="Horário de Início"
                                            type="time"
                                            value={values.startTime}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            error={touched.startTime && Boolean(errors.startTime)}
                                            helperText={touched.startTime && errors.startTime}
                                            onChange={(e) => {
                                                setFieldValue('startTime', e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name="endTime"
                                            as={TextField}
                                            label="Horário de Fim"
                                            type="time"
                                            value={values.endTime}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            error={touched.endTime && Boolean(errors.endTime)}
                                            helperText={touched.endTime && errors.endTime}
                                            onChange={(e) => {
                                                setFieldValue('endTime', e.target.value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <div style={{ marginTop: 4 }}>
                                    <FormControlLabel
                                        control={
                                            <Field
                                                name="multipleEvents"
                                                as={Checkbox}
                                                checked={values.multipleEvents}
                                                onChange={(e) => setFieldValue('multipleEvents', e.target.checked)}
                                            />
                                        }
                                        label="Permitir múltiplos eventos no mesmo horário"
                                    />
                                </div>


                                <DialogActions>
                                    <Button onClick={handleClose} color="secondary" disabled={isSubmitting}>
                                        Cancelar
                                    </Button>
                                    <Button type="submit" color="primary" disabled={isSubmitting}>
                                        Atualizar
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default AgendaModalEdit;
