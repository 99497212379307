import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    IconButton,
    RadioGroup,
    Radio,
    FormControlLabel,
    Typography,
    Divider,
    Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import api from '../../services/api';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    addButton: {
        marginTop: theme.spacing(2),
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row', // Deixa os rádios em linha
    },
    btnContainer: {
        textAlign: "right",

    },
}));

const RoutineSchema = Yup.object().shape({
    messages: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('Título é obrigatório'),
            message: Yup.string().required('Mensagem é obrigatória'),
            timeBefore: Yup.string().required('Tempo é obrigatório'),
        })
    ),
});

const AgendaRoutineModal = ({ open, handleClose }) => {
    const classes = useStyles();

    const initialValues = {
        messages: [
            {
                title: '',
                message: '',
                timeBefore: '',
                timeUnit: '',
                confirmation: '',
            },
        ],
    };

    const handleSubmit = async (values) => {

        console.log(values);

        try {

            const response = await api.post('/agendas/routine', values);
            console.log(response.data);

            toast.success('Rotina cadastrada com sucesso!');

        } catch (error) {
            console.log(error);
            toast.error('Erro ao cadastrar rotina!');
        }

        handleClose();
    };

    

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
            <DialogTitle id="form-dialog-title">Cadastro de Rotina</DialogTitle>
            <DialogContent dividers>
                <Formik
                    initialValues={initialValues}
                    validationSchema={RoutineSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, errors, touched, handleChange, handleBlur }) => (
                        <Form>
                            <FieldArray name="messages">
                                {({ insert, remove, push }) => (
                                    <div>
                                        {values.messages.map((_, index) => (
                                            <div key={index}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Field
                                                            name={`messages[${index}].title`}
                                                            as={TextField}
                                                            label="Título"
                                                            fullWidth
                                                            margin="dense"
                                                            variant="outlined"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.messages[index].title}
                                                            error={touched?.messages?.[index]?.title && Boolean(errors?.messages?.[index]?.title)}
                                                            helperText={touched?.messages?.[index]?.title && errors?.messages?.[index]?.title}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Field
                                                            name={`messages[${index}].message`}
                                                            as={TextField}
                                                            label="Mensagem"
                                                            fullWidth
                                                            variant="outlined"
                                                            multiline
                                                            rows={5}
                                                            margin="dense"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.messages[index].message}
                                                            error={touched?.messages?.[index]?.message && Boolean(errors?.messages?.[index]?.message)}
                                                            helperText={touched?.messages?.[index]?.message && errors?.messages?.[index]?.message}
                                                        />
                                                    </Grid>
                                                    <Grid item container xs={7} direction="row">

                                                        <Grid item>
                                                            <Field
                                                                name={`messages[${index}].timeBefore`}
                                                                as={TextField}
                                                                label="Tempo para o envio"
                                                                fullWidth
                                                                variant="outlined"
                                                                margin="dense"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.messages[index].timeBefore}
                                                                error={touched?.messages?.[index]?.timeBefore && Boolean(errors?.messages?.[index]?.timeBefore)}
                                                                helperText={touched?.messages?.[index]?.timeBefore && errors?.messages?.[index]?.timeBefore}
                                                            />
                                                        </Grid>
                                                        <Grid item style={{ marginLeft: 10 }}>
                                                            <RadioGroup
                                                                name={`messages[${index}].timeUnit`}
                                                                value={values.messages[index].timeUnit}
                                                                onChange={handleChange}
                                                                className={classes.radioGroup}
                                                            >
                                                                <FormControlLabel
                                                                    value="minutos"
                                                                    control={<Radio />}
                                                                    label="minutos"
                                                                />
                                                                <FormControlLabel
                                                                    value="horas"
                                                                    control={<Radio />}
                                                                    label="horas"
                                                                />
                                                                <FormControlLabel
                                                                    value="dias"
                                                                    control={<Radio />}
                                                                    label="dias"
                                                                />
                                                            </RadioGroup>
                                                            {touched?.messages?.[index]?.timeUnit && errors?.messages?.[index]?.timeUnit && (
                                                                <div style={{ color: 'red' }}>{errors.messages[index].timeUnit}</div>
                                                            )}
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item container xs={12} direction="row">
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                variant="body2"
                                                                style={{ fontSize: "1rem" }}
                                                            >Precisa de confirmação?
                                                            </Typography>
                                                            <Field
                                                                name={`messages[${index}].confirmation`}
                                                                as={Select}
                                                                label="Precisa de confirmação"
                                                                size="small"
                                                                variant="outlined"
                                                                margin="dense"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.messages[index].confirmation}
                                                                error={touched?.messages?.[index]?.confirmation && Boolean(errors?.messages?.[index]?.confirmation)}
                                                                helperText={touched?.messages?.[index]?.confirmation && errors?.messages?.[index]?.confirmation}
                                                            >
                                                                <option value="true">Sim</option>
                                                                <option value="false">Não</option>
                                                            </Field>
                                                        </Grid>
                                                        <Grid item style={{ marginLeft: "auto" }}>
                                                            <IconButton
                                                                size="small"
                                                                color="secondary"
                                                                onClick={() => remove(index)}
                                                                disabled={values.messages.length === 1} // Prevenir remoção se for o último
                                                            >
                                                                <Typography variant="body2">Remover</Typography> <DeleteOutlineIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {index < values.messages.length - 1 && (<Divider style={{ margin: "20px 0" }} />)}
                                            </div>
                                        ))}

                                        <Divider style={{ marginTop: "10px" }} />
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() =>
                                                push({
                                                    title: '',
                                                    message: '',
                                                    timeBefore: '',
                                                    timeUnit: '',
                                                    confirmation: '',
                                                })
                                            }
                                            startIcon={<AddIcon />}
                                            className={classes.addButton}
                                        >
                                            Mais Mensagem
                                        </Button>
                                    </div>
                                )}
                            </FieldArray>

                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    Cancelar
                                </Button>
                                <Button type="submit" color="primary">
                                    Salvar
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default AgendaRoutineModal;