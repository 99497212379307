import openSocket from "socket.io-client";
import { getBackendUrl } from "../config";
import { set } from "date-fns";
function connectToSocket() {
  const token = localStorage.getItem("token");
  const socket = openSocket(getBackendUrl(), {
    pingIntenal: 10000,
    pingTimeout: 5000,
    query: {
      token: JSON.parse(token),
    },
    //autoConnect: true,
    //reconnection: true,
    //reconnectionAttempts: Infinity,
    //reconnectionDelay: 5000,
    //reconnectionDelayMax: 300000,
  });

  console.log("Connected to socket");

  socket.on("disconnect", (reason) => {
    console.log("Disconnected from socket");
    console.log(reason);
    if (reason === 'io server disconnect') {
      socket.connect();
    }
  });

  /*socket.on("connection", () => {
    console.log("Connected to socket");
  });*/

  return socket;
}

export default connectToSocket;
