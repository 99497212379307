import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useState, useEffect, useContext } from 'react';
import useWhatsApps from '../../hooks/useWhatsApps';
import { AuthContext } from '../../context/Auth/AuthContext';
import { Can } from "../Can";

import api from '../../services/api';

import { i18n } from "../../translate/i18n";
import { toast } from 'react-toastify';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
}));

const daysOfWeek = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];

const AgendaSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
});

const AgendaModal = ({ open, handleClose }) => {
    const classes = useStyles();

    const initialValues = {
        name: '',
        selectedDays: [],
        startTime: '',
        endTime: '',
        multipleEvents: false,
    };

    const { user: loggedInUser } = useContext(AuthContext);

    const { loading, whatsApps } = useWhatsApps();
    const [whatsappId, setWhatsappId] = useState(false);


    const handleSubmit = async (values) => {
        const agendaData = { ...values, whatsappId };

        console.log(agendaData);

        try {
            const response = await api.post('/agendas', agendaData);

            toast.success('Agenda criada com sucesso!');
        } catch (error) {
            toast.error('Erro ao criar agenda!');
        }

        handleClose();
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Criar Agenda</DialogTitle>
            <DialogContent dividers>
                <Formik
                    initialValues={initialValues}
                    validationSchema={AgendaSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, handleChange, handleBlur, setFieldValue, isSubmitting, errors, touched }) => (
                        <Form>
                            <div className={classes.multFieldLine}>
                                <Field
                                    name="name"
                                    as={TextField}
                                    label="Nome da agenda"
                                    fullWidth
                                    margin="dense"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                />

                                <Can
                                    role={loggedInUser.profile}
                                    perform="user-modal:editQueues"
                                    yes={() => (!loading &&
                                        <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                                            <InputLabel>{i18n.t("userModal.form.whatsapp")}</InputLabel>
                                            <Field
                                                as={Select}
                                                value={whatsappId}
                                                onChange={(e) => setWhatsappId(e.target.value)}
                                                label={i18n.t("userModal.form.whatsapp")}
                                            >
                                                <MenuItem value={''}>&nbsp;</MenuItem>
                                                {whatsApps.map((whatsapp) => (
                                                    <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
                                                ))}
                                            </Field>
                                        </FormControl>
                                    )}
                                />
                            </div>

                            <Typography variant="h6" style={{ fontSize: "1rem", marginTop: 5 }}>
                                Dias da Semana
                            </Typography>

                            <Grid container spacing={2}>
                                {daysOfWeek.map((day) => (
                                    <Grid item xs={6} key={day}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.selectedDays.includes(day)}
                                                    onChange={() => {
                                                        const selected = values.selectedDays.includes(day)
                                                            ? values.selectedDays.filter((d) => d !== day)
                                                            : [...values.selectedDays, day];
                                                        setFieldValue('selectedDays', selected);
                                                    }}
                                                />
                                            }
                                            label={day}
                                        />
                                    </Grid>
                                ))}
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Field
                                        name="startTime"
                                        as={TextField}
                                        label="Horário de Início"
                                        type="time"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.startTime}
                                        error={touched.startTime && Boolean(errors.startTime)}
                                        helperText={touched.startTime && errors.startTime}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        name="endTime"
                                        as={TextField}
                                        label="Horário de Fim"
                                        type="time"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.endTime}
                                        error={touched.endTime && Boolean(errors.endTime)}
                                        helperText={touched.endTime && errors.endTime}
                                    />
                                </Grid>
                            </Grid>
                            <div style={{ marginTop: 4 }}>
                                <FormControlLabel
                                    control={
                                        <Field
                                            name="multipleEvents"
                                            as={Checkbox}
                                            checked={values.multipleEvents}
                                            onChange={(e) => setFieldValue('multipleEvents', e.target.checked)}
                                        />
                                    }
                                    label="Permitir múltiplos eventos no mesmo horário"
                                />
                            </div>

                            <DialogActions>
                                <Button onClick={handleClose} color="secondary" disabled={isSubmitting}>
                                    Cancelar
                                </Button>
                                <Button type="submit" color="primary" disabled={isSubmitting}>
                                    Salvar
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default AgendaModal;
